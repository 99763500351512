import { updateState } from '../../models';

export const setIntegrationConnected = async (
  integrationName: 'instapaper' | 'pocket' | 'twitter' | 'gmail',
) => {
  await updateState(
    (state) => {
      if (!state.persistent.integrations) {
        state.persistent.integrations = {};
      }
      state.persistent.integrations[integrationName] = { connected: true };
    },
    { eventName: 'integration-connected', userInteraction: null, isUndoable: false },
  );
};

export const removeIntegration = async (
  integrationName: 'instapaper' | 'pocket' | 'twitter' | 'gmail',
) => {
  await updateState(
    (state) => {
      if (!state.persistent.integrations) {
        state.persistent.integrations = {};
      }
      if (state.persistent.integrations[integrationName]) {
        delete state.persistent.integrations[integrationName];
      }
    },
    { eventName: 'integration-disconnected', userInteraction: null, isUndoable: false },
  );
};
