import React from 'react';

import Icon from './Icon';

export default function LogoIcon({ className = '' }): JSX.Element {
  return (
    <Icon text="Logo">
      <svg
        className={className}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="17" height="17" fill="white" />
        <path
          d="M0.34413 16.6559V0.34413H16.6559V16.6559H0.34413Z"
          stroke="black"
          strokeWidth="0.688259"
          strokeMiterlimit="10"
        />
        <path
          d="M12.3156 12.1671C12.6741 12.7634 12.9664 12.9616 13.5644 13.0326V13.6289H10.6143L7.85089 9.0172H7.16046V12.0399C7.16046 12.8493 7.36032 12.9616 8.21098 13.0326V13.6289H3.75781V13.0326C4.60847 12.9616 4.80834 12.8477 4.80834 12.0399V5.28585C4.80834 4.46327 4.6349 4.36417 3.75781 4.29314V3.69685H8.34147C11.1049 3.69685 12.5485 4.27828 12.5485 6.32151C12.5485 7.86757 11.797 8.60591 10.2823 8.86028L12.3156 12.1671ZM7.55853 4.66314C7.31902 4.66314 7.16046 4.83327 7.16046 5.08929V8.05422H8.30348C9.48615 8.05422 10.1898 7.62806 10.1898 6.32317C10.1898 5.0034 9.48615 4.66314 8.34312 4.66314H7.55853Z"
          fill="black"
        />
        <path d="M10.6573 4.05215H6.35938V8.15514H10.6573V4.05215Z" fill="black" />
        <path
          d="M10.117 4.76072C9.88248 5.19183 10.2442 7.22351 10.2442 7.22351C10.2492 7.33087 10.1765 7.42502 10.0708 7.44815C10.0708 7.44815 7.85576 7.45806 7.12402 8.06591"
          fill="#F4F9F9"
        />
      </svg>
    </Icon>
  );
}
